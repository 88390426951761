/* / Customization Css Start Here / */
header {
	position: unset;
}
section.customization_section .top_heading h2 {
	color: #000000;
	font-weight: 700;
	font-size: 52px;
	/* font-family: "Generica"; */
}
section.customization_section .top_heading h2 span {
	color: #d6a857;
}
@media (min-width: 768px) {
	.resposive_padding {
		padding-left: 0px;
	}
}
.luggage_description a.btn {
	/* font-family: "Segoe UI"; */
	font-size: 13px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 2px;
	padding: 13px 53px;
	width: 217px;
}
/* / Custom Checkbox Start here / */
.custom_check_box {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	color: #636060;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: 12px;
	font-family: "Montserrat", sans-serif;
	font-weight: 400;
}
/* / Hide the browser's default checkbox / */
.custom_check_box input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
/* / Create a custom checkbox / */
.custom_check_box .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 21px;
	width: 22px;
	background-color: #eee;
	border: 1px solid #636060;
	border-radius: 4px;
	background: transparent;
}
section.checkout_section .checkout_start .save_details {
	margin-top: 14px;
}
/* / Create the checkmark/indicator (hidden when not checked) / */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}
/* / Show the checkmark when checked / */
.custom_check_box input:checked ~ .checkmark:after {
	display: block;
}
/* / Style the checkmark/indicator / */
.custom_check_box .checkmark:after {
	left: 6px;
	top: 1px;
	width: 7px;
	height: 13px;
	border: solid #fdae25;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
/* / Custom Check Box End Here / */

section.customization_section .customization_start_here {
	padding-top: 61px;
}
section.customization_section
	.customization_start_here
	.left_side_start
	.bag_lg_photo {
	/* padding: 43px 74px 27px; */
	border: 1px solid #707070;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	margin-bottom: 33px;
}
section.customization_section
	.customization_start_here
	.left_side_start
	.bag_lg_photo
	figure {
	margin-bottom: 0;
}
section.customization_section
	.customization_start_here
	.left_side_start
	.small_images {
	padding-top: 22px;
	padding-bottom: 37px;
}
section.customization_section
	.customization_start_here
	.left_side_start
	.accesories {
	padding-top: 15px;
}
section.customization_section
	.customization_start_here
	.left_side_start
	.accesories
	.accesorries_list {
	padding-top: 45px;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}
section.customization_section
	.customization_start_here
	.left_side_start
	.accesories
	.accesorries_list
	figure {
	max-width: 105px;
	min-width: 102px;
	padding: 12px;
	max-height: 99px;
	border: 1px solid #0000004f;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 0px;
	cursor: pointer;
}
section.customization_section
	.customization_start_here
	.left_side_start
	.accesories
	.accesorries_list
	figure
	img {
	max-width: 100%;
}
section.customization_section
	.customization_start_here
	.left_side_start
	.accesories
	.accesorries_list
	.single_accesory
	input.accesory_input:checked
	+ label
	figure {
	border-color: #d29f45;
}
.luggage_description .the_heading h5 {
	font-family: "Montserrat", sans-serif;
	font-size: 22px;
	color: #262626;
	text-transform: uppercase;
	font-weight: 600;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list {
	display: flex;
	flex-direction: column;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 28px 13px;
	border-bottom: 1px solid #00000036;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage
	.luggae_details {
	display: flex;
	gap: 12px;
	align-items: self-start;
	width: 70%;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage
	.lugage_quantity {
	display: flex;
	align-items: center;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage
	.luggae_details
	figure {
	padding: 5px 10px;
	background: #ffffff;
	border: 1px solid #d29f45;
	margin-bottom: 0;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage
	.luggae_details
	figure
	img {
	max-width: 47px;
	max-height: 63px;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage
	.desc
	span.price {
	display: block;
	font-size: 16px;
	font-weight: 800;
	font-family: "Montserrat", sans-serif;
	color: #5e5e5e;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage
	.desc
	span.name {
	color: #5e5e5e;
	font-size: 16px;
	font-family: "Montserrat", sans-serif;
	font-weight: 400;
	display: block;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage
	a {
	color: #d29f45;
	font-size: 13px;
	font-weight: 400;
	font-family: "Montserrat", sans-serif;
	text-decoration: underline;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage
	.lugage_quantity
	input {
	max-width: 66px;
	outline: none;
	text-align: center;
	color: #343436 !important;
	font-size: 13px;
	background: #f2f2f2;
	font-family: "Montserrat", sans-serif;
	border: 0px;
	height: 36px;
	margin-right: 3px;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage
	.lugage_quantity
	input::-webkit-outer-spin-button,
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage
	.lugage_quantity
	input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage
	.lugage_quantity
	span {
	color: #5e5e5e;
	font-size: 16px;
	font-family: "Montserrat", sans-serif;
	padding-right: 11px;
}
.quantity_controller {
	display: flex;
	flex-direction: column;
	gap: 2px;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage
	.lugage_quantity
	span.increment_quantity,
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage
	.lugage_quantity
	span.decrement_quantity {
	background: #f2f2f2;
	line-height: 3px;
	padding: 8px 4px;
	cursor: pointer;
	height: 15px;
	display: block;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage
	.lugage_quantity
	span.increment_quantity
	i,
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage
	.lugage_quantity
	span.decrement_quantity
	i {
	font-weight: 600;
	line-height: 2px;
	font-size: 16px;
	color: #5e5e5e !important;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_steps {
	padding-top: 25px;
	display: flex;
	flex-direction: column;
	gap: 17px;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_steps
	.single_step
	.text
	span.step_no {
	color: #d29f45;
	font-size: 15px;
	font-weight: 600;
	font-family: "Montserrat", sans-serif;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_steps
	.single_step
	h4 {
	margin-bottom: 0px;
	color: #262626;
	font-size: 22px;
	font-weight: 600;
	font-family: "Montserrat", sans-serif;
	text-transform: uppercase;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_steps
	.single_step
	p {
	margin-bottom: 0px;
	color: #262626;
	font-size: 14px;
	font-weight: 400;
	font-family: "Montserrat", sans-serif;
	margin-top: 6px;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_steps
	.single_step {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_steps
	.single_step
	.colors
	.single_color
	label
	span {
	display: block;
	height: 22px;
	width: 22px;
	cursor: pointer;
	border: 2px solid #000000 !important;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_steps
	.single_step
	.colors
	.single_color
	label
	span.black {
	background-color: #000000;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_steps
	.single_step
	.colors
	.single_color
	label
	span.red {
	background-color: #c61a34;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_steps
	.single_step
	.colors
	.single_color
	label
	span.blue {
	background-color: #104388;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_steps
	.single_step
	.colors
	.single_color
	label
	span.white {
	background-color: #f6f6f6;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_steps
	.single_step
	.colors {
	display: flex;
	gap: 5px;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_steps
	.single_step
	.colors
	label {
	margin-bottom: 0px;
	display: unset;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_steps
	.single_step
	.colors
	.single_color
	.body_colors:checked
	+ label
	span {
	border-color: #d29f45;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_steps
	.single_step
	.colors
	.single_color
	label
	span.white_1 {
	background: #7d7d7d;
}
span.white_05 {
	background: #c8c8c6;
}
.luggage_description .the_heading p {
	margin-bottom: 0px;
	color: #262626;
	font-size: 14px;
	font-weight: 400;
	font-family: "Montserrat", sans-serif;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage.selected
	* {
	color: #fff !important;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	.single_lugage.selected {
	background: #d6a857;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.final_customized_bag
	figure {
	border: 1px solid #707070;
	max-width: 34%;
	padding: 5px 29px;
	text-align: center;
	margin-bottom: 0px;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.final_customized_bag
	figure
	img {
	max-width: 100%;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.final_customized_bag {
	padding-top: 33px;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.final_customized_bag
	.ep_input {
	max-width: 264px;
	background: #f1f1f1;
	display: flex;
	justify-content: right;
	height: 45px;
	align-items: center;
	margin-top: 26px;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.final_customized_bag
	.ep_input
	input {
	border: 0;
	background: transparent;
	outline: none;
	margin-left: 13px;
	color: #636060;
	font-size: 14px;
	font-weight: 400;
	font-family: "Montserrat", sans-serif;
	padding-right: 12px;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.final_customized_bag
	.ep_input
	label {
	margin-bottom: 0px;
	color: #636060;
	font-size: 14px;
	font-weight: 400;
	font-family: "Montserrat", sans-serif;
}
input.the_bags_list:checked + label .single_lugage {
	background: #d6a857;
}
input.the_bags_list:checked + label .single_lugage * {
	color: #fff !important;
}
section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_list
	label {
	margin-bottom: 0;
}
section.customization_section .customization_start_here .proced_to_checkout {
	position: relative;
	text-align: end;
	display: flex;
	align-items: center;
	justify-content: right;
	margin-top: 64px;
}
section.customization_section
	.customization_start_here
	.proced_to_checkout:before {
	content: "";
	width: 100%;
	height: 1px;
	display: block;
	position: absolute;
	background: #707070;
}
section.customization_section
	.customization_start_here
	.proced_to_checkout
	.chck_btn {
	z-index: 9999;
	padding-left: 8px;
	background: #fff;
}
section.customization_section {
	padding: 46px 0px 116px;
}

@media (max-width: 991px) {
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_list
		.single_lugage {
		flex-direction: column;
		gap: 12px;
		padding: 14px;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_list
		.single_lugage
		.luggae_details {
		margin-right: auto;
		width: 100%;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_list
		.single_lugage
		.lugage_quantity {
		margin-left: auto;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_steps
		.single_step
		h4 {
		font-size: 17px;
	}
	section.customization_section .btn {
		font-size: 12px;
		padding: 10px 22px;
		letter-spacing: 1px;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.final_customized_bag
		figure {
		max-width: 50%;
	}
	.luggage_description .the_heading h5 {
		font-size: 17px;
	}
	.luggage_description .the_heading p {
		font-size: 12px;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_steps
		.single_step
		.text
		span.step_no {
		font-size: 13px;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_steps
		.single_step
		p {
		font-size: 12px;
	}
	section.customization_section .top_heading h2 {
		font-size: 32px;
	}
}
@media (max-width: 768px) {
	section.customization_section .top_heading h2 {
		font-size: 28px;
	}
	section.customization_section .customization_start_here .luggage_description {
		padding-top: 43px;
	}
	section.customization_section .customization_start_here .luggage_description {
		padding-top: 43px;
	}
}
@media (max-width: 576px) {
	.shop-banner .shop-banner-content-wrapper h2 {
		font-family: "Generica";
		font-size: 55px;
		font-weight: 700;
		color: #000000;
	}
}
@media (max-width: 576px) {
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_list
		.single_lugage
		.luggae_details
		figure
		img {
		max-width: 35px;
		max-height: 47px;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_list
		.single_lugage
		.desc
		span.price {
		font-size: 13px;
		margin-bottom: 5px;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_list
		.single_lugage
		.desc
		span.name {
		font-size: 13px;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_list
		.single_lugage
		a {
		font-size: 11px;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_list
		.single_lugage
		.desc
		span.name {
		font-size: 13px;
		line-height: 7px;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_list
		.single_lugage
		.lugage_quantity
		input {
		max-width: 43px;
		max-height: 30px;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_list
		.single_lugage
		.lugage_quantity
		span.increment_quantity
		i,
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_list
		.single_lugage
		.lugage_quantity
		span.decrement_quantity
		i {
		font-size: 12px;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_list
		.single_lugage
		.lugage_quantity
		span.increment_quantity,
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_list
		.single_lugage
		.lugage_quantity
		span.decrement_quantity {
		padding: 4px 4px;
		height: 14px;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_list
		.single_lugage
		.lugage_quantity
		span {
		font-size: 14px;
	}
	.luggage_description .the_heading h5 {
		font-size: 14px;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_steps
		.single_step
		h4 {
		font-size: 13px;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_steps
		.single_step
		p {
		margin-top: 0px;
	}
	section.customization_section
		.customization_start_here
		.luggage_description
		.luggage_steps
		.single_step
		.text
		span.step_no {
		font-size: 11px;
	}
	section.customization_section
		.customizatiaon_start_here
		.left_side_start
		.small_images
		figure
		img {
		max-width: 29%;
	}
	section.customization_section .btn {
		font-size: 11px;
		letter-spacing: 0px;
		padding: 9px 16px;
	}
	section.customization_section .top_heading h2 {
		font-size: 23px;
	}
	section.customization_section
		.customization_start_here
		.left_side_start
		.bag_lg_photo {
		padding: 25px;
	}
}
.customization_section div#customizationModule canvas {
    height: 450px !important;
}

section.customization_section
	.customization_start_here
	.luggage_description
	.luggage_steps
	.single_step
	.colors
	.single_color
	label
	span.gray {
	background: gray;
}
.for_the_radios label {
	display: block;
	margin-bottom: 0px;
}

.for_the_radios {
	margin: 27px 0px 33px;
}
